<div class="charging-info-duration">
  <div class="charging-info-icon">
    <svg
      version="1.1"
      id="innogy_picto_clock.svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 85.04 85.04"
    >
      <title>innogy_picto_clock</title>
      <g
        id="innogy_picto_clock"
        fill="currentColor"
        shape-rendering="geometricPrecision"
        color-rendering="optimizeQuality"
      >
        <path
          id="circle"
          d="M42.51953,85.03906A42.51953,42.51953,0,1,1,85.03906,42.51953,42.07364,42.07364,0,0,1,75.84863,68.9248a2.12636,2.12636,0,0,1-3.332-2.64258,37.85809,37.85809,0,0,0,8.27051-23.7627A38.25583,38.25583,0,1,0,63.63672,74.43848a2.12645,2.12645,0,1,1,2.34961,3.54492A42.34347,42.34347,0,0,1,42.51953,85.03906Z"
        />
        <path
          id="pointer"
          d="M56.07031,53.06445L44.64551,41.63916V19.13379a2.126,2.126,0,1,0-4.252,0V42.51953a2.11907,2.11907,0,0,0,.62469,1.50507l12.0462,12.04572A2.12546,2.12546,0,0,0,56.07031,53.06445Z"
        />
      </g>
    </svg>
  </div>
  <h1 class="value">
    <small>{{ 'session.status.slide-duration.circa' | translate }} </small
    >{{ duration.substr(0, duration.length - 3) }} <small>h</small>
  </h1>
  <div class="description">{{ 'session.status.slide-duration.currently-loaded' | translate }}</div>
</div>
