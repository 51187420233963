import { Component, Input } from '@angular/core';

@Component({
  selector: 'ec-charge-session-status-slide-duration',
  templateUrl: './charge-session-status-slide-duration.component.html',
  styleUrls: ['./charge-session-status-slide-duration.component.scss'],
})
export class ChargeSessionStatusSlideDurationComponent {
  @Input() duration = '';

  constructor() {}
}
