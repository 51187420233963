import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageHistoryService {
  private subject = new Subject<any>();

  constructor() {}

  public getPageHistory(): Observable<any> {
    return this.subject.asObservable();
  }

  public setPageHistory(data) {
    localStorage.setItem('page-history', JSON.stringify(data));
    this.subject.next(data);
  }

  public updatePageHistory(data, oldPath) {
    const pageHistory = JSON.parse(localStorage.getItem('page-history'));
    const newHistory: Array<string> = [];
    const regex = new RegExp(oldPath, 'i');
    pageHistory.forEach(el => {
      if (el.search(regex) === -1) {
        newHistory.push(el);
      }
    });
    newHistory.push(data);
    localStorage.setItem('page-history', JSON.stringify(newHistory));
    this.subject.next(newHistory);
  }

  public pageHistoryBack(): string {
    const pageHistory = JSON.parse(localStorage.getItem('page-history'));
    if (pageHistory.length > 1) {
      pageHistory.pop();
    }

    localStorage.setItem('page-history', JSON.stringify(pageHistory));
    this.subject.next(pageHistory);
    return pageHistory[pageHistory.length - 1];
  }

  public clearPageHistory() {
    localStorage.setItem('page-history', JSON.stringify({}));
    this.subject.next();
  }
}
