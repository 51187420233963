import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EopIconModule } from '../shared/components/eop-icon/eop-icon.module';
import { MaterialDesignModule } from '../shared/modules/material-design/material-design.module';
import { ChargingOverlayModule } from './charging-overlay/charging-overlay.module';
import { ComponentsRoutingModule } from './components-routing.module';
import { EChargeBannerComponent } from './echarge-banner/echarge-banner.component';
import { LoadingOverlayModule } from './loading-overlay/loading-overlay.module';
import { MenuPagesModule } from './menu-pages/menu-pages.module';

@NgModule({
  imports: [
    ComponentsRoutingModule,
    MaterialDesignModule,
    MaterialDesignModule,
    EopIconModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    ChargingOverlayModule,
    LoadingOverlayModule,
    MenuPagesModule,
    TranslateModule,
    MatBottomSheetModule,
  ],
  exports: [
    ComponentsRoutingModule,
    MaterialDesignModule,
    EopIconModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MenuPagesModule,
    RouterModule,
    ChargingOverlayModule,
    LoadingOverlayModule,
  ],
  declarations: [EChargeBannerComponent],
})
export class ComponentsModule {
  constructor() {}
}
