import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { OpenPageModel } from './components/menu-pages/model/open-page.model';
import { OpenPageService } from './components/menu-pages/services/open-page.service';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import {
  GoogleAnalyticsAction,
  GoogleAnalyticsCategory,
} from './shared/services/google-analytics/google-analytics.model';
import { GoogleAnalyticsService } from './shared/services/google-analytics/google-analytics.service';
import { PageHistoryService } from './shared/services/page-history.service';
import { SessionService } from './shared/services/session.service';
import { SidenavService } from './shared/services/sidenav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('sidenav', { static: true })
  public sidenav: MatSidenav;

  public opened: boolean;
  public showBackgroundMap: boolean;
  public openPage: OpenPageModel;
  public sessionExistence = false;
  public isChargingOverlayCollapsed$: Observable<boolean>;
  private isHome: boolean;

  constructor(
    private translate: TranslateService,
    private pageHistoryService: PageHistoryService,
    private router: Router,
    public matDialog: MatDialog,
    private route: ActivatedRoute,
    private openPageService: OpenPageService,
    private sidenavService: SidenavService,
    private sessionService: SessionService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.sessionExistence = this.sessionService.getSessionExistence();

    this.initLanguagesAndLocale();

    this.route.queryParams.subscribe(params => {
      if (params['translate']) {
        if (
          environment.mainUrl.includes('dev') ||
          environment.mainUrl.includes('int') ||
          environment.mainUrl.includes('qa')
        ) {
          translate.setTranslation('en', {});
          translate.setTranslation('de', {});
        }
      }
      if (params['paypal'] === 'error') {
        this.sessionService.clearSession();
        this.matDialog.open(DialogComponent, {
          data: {
            headline: 'payment.error.headline',
            text: 'payment.error.paypal',
            button2: 'payment.error.ok',
          },
        });
      } else if (params['paypal'] === 'back') {
        this.sessionService.clearSession();
        const dialogRef = this.matDialog.open(DialogComponent, {
          data: {
            headline: 'payment.abort.headline',
            button2: 'payment.abort.ok',
          },
        });
      } else if (params['cc'] === 'error') {
        this.sessionService.clearSession();
        this.matDialog.open(DialogComponent, {
          data: {
            headline: 'payment.error.headline',
            text: 'payment.error.cc',
            button2: 'payment.error.ok',
          },
        });
      } else if (params['cc'] === 'back') {
        this.sessionService.clearSession();
        const dialogRef = this.matDialog.open(DialogComponent, {
          data: {
            headline: 'payment.abort.headline',
            button2: 'payment.abort.ok',
          },
        });
      }
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isHome = event.url === '/';
      });
  }

  public ngOnInit() {
    this.googleAnalyticsService.listenToRouteChanges();
    this.sidenavService.setSidenav(this.sidenav);
    this.openPageService.currentOpenPage.subscribe(openPage => {
      this.openPage = openPage;
    });
    this.sessionService.getSessionExistenceSubscription().subscribe(existence => {
      this.sessionExistence = existence;
    });
    this.isChargingOverlayCollapsed$ = this.sessionService.isOverlayCollapsed$;
  }

  public setActiveComponent(componentRef) {
    if (componentRef.openPage) {
      componentRef.openPage.subscribe(event => {
        this.onOpenPage(event);
      });
    }
    this.showBackgroundMap = componentRef.isStartPage && componentRef.chargePortFound === false;
  }

  public navigateBack() {
    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.TOOLBAR,
      GoogleAnalyticsAction.BACK,
      'Back button pressed'
    );
    const url = this.pageHistoryService.pageHistoryBack();
    this.router.navigate([url]);
  }

  public closeMenuPages(sidenav) {
    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.TOOLBAR,
      GoogleAnalyticsAction.TOGGLE_MENU,
      'close'
    );
    sidenav.toggle();
    this.openPageService.closeOpenPage();
  }

  public onOpenPage(event) {
    switch (event) {
      case 'contactPage':
        this.sidenav.open();
        this.openPage.contactPageOpen = true;
        this.openPage.selectedPageOpen = true;
        this.openPageService.changeOpenPage(this.openPage);
        break;

      case 'terms':
        this.sidenav.open();
        this.openPage.termsOpen = true;
        this.openPage.selectedPageOpen = true;
        this.openPageService.changeOpenPage(this.openPage);
        break;
    }
  }

  public backButtonVisible() {
    return (!this.sessionExistence && !this.isHome) || this.opened;
  }

  menuOpened(byToolbar = false) {
    this.googleAnalyticsService.sendEvent(
      byToolbar ? GoogleAnalyticsCategory.TOOLBAR : GoogleAnalyticsCategory.MENU,
      GoogleAnalyticsAction.TOGGLE_MENU,
      'open'
    );
  }

  menuClosed() {
    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.MENU,
      GoogleAnalyticsAction.TOGGLE_MENU,
      'close'
    );
  }

  private initLanguagesAndLocale() {
    const registeredLanguages = [
      'cs',
      'da',
      'de',
      'en',
      'es',
      'fi',
      'fr',
      'hu',
      'it',
      'nl',
      'nn',
      'pl',
      'sk',
      'sv',
    ];
    const browserLanguage = this.translate.getBrowserLang();
    let usedLanguage = registeredLanguages.includes(browserLanguage) ? browserLanguage : 'en';

    // this.registerLocale(usedLanguage);
    this.translate.use(usedLanguage);
    this.translate.defaultLang = 'en';
    moment.locale(usedLanguage);
  }

  // private async registerLocale(language: string) {
  //   const module = await import(
  //     /* webpackInclude: /(cs|da|de|en|es|fi|fr|hu|it|nl|nn|pl|sk|sv)\.js$/ */
  //     `@angular/common/locales/${language}.js`
  //   );
  //   registerLocaleData(module.default);
  // }
}
