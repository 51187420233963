import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

export interface Icon {
  identifier: string;
  weight?: IconWeight;
  size?: IconSize;
  color?: IconColor;
  clickable?: boolean;
  dataTestId?: string;
  tooltip?: string;
}

export enum IconWeight {
  LIGHT = 'light',
  BOLD = 'bold',
  REGULAR = 'regular',
}

export enum IconColor {
  NORMAL = 'normal',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  DISABLED = 'disabled',
  CLICKABLE = 'clickable',
  LIGHT = 'light',
  NAVIGATIONAL = 'navigational',
  INHERIT = 'inherit',
  WHITE = 'white',
}

export enum IconSize {
  MICRO = 'micro',
  MINI = 'mini',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

@Component({
  selector: 'eop-icon',
  templateUrl: './eop-icon.component.html',
  styleUrls: ['./eop-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EopIconComponent implements OnChanges {
  @Input()
  public identifier: string;

  @Input()
  public color: IconColor;

  @Input()
  public weight: IconWeight = IconWeight.REGULAR;

  @Input()
  public size: IconSize = IconSize.NORMAL;

  @Input()
  public clickable: boolean = false;

  @Input()
  public dataTestId: string = null;

  @Input()
  public tooltip: string;

  public iconClasses: string;

  ngOnChanges() {
    if (!this.weight) {
      this.weight = IconWeight.REGULAR;
    }
    if (!this.size) {
      this.size = IconSize.NORMAL;
    }
    if (this.identifier && this.identifier !== '') {
      // Set the icon to display and optionally its weight
      const baseClass = `icon-${this.identifier}`;
      this.iconClasses = this.weight ? `${baseClass} ${this.weight}` : baseClass;

      // Set the size - NORMAL = default size
      if (this.size !== IconSize.NORMAL) {
        this.iconClasses += ' icon-' + this.size;
      }

      // Set clickable - cursor pointer and secondary Color - (close = black color - should this be done here?)
      if (this.clickable) {
        this.identifier === 'close'
          ? (this.iconClasses += ' icon-close-clickable')
          : (this.iconClasses += ' icon-clickable');
      }

      if (this.color) {
        // Set color
        this.iconClasses += ' icon-' + this.color;
      }
    } else {
      // Clear Icon
      this.iconClasses = '';
    }
  }
}
