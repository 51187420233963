export class OpenPageModel {
  private _selectedPageOpen: boolean;
  private _feedbackPageOpen: boolean;
  private _contactPageOpen: boolean;
  private _imprintOpen: boolean;
  private _helpOpen: boolean;
  private _termsOpen: boolean;

  constructor() {}

  public closeAllMenuPages() {
    this.selectedPageOpen = false;
    this.feedbackPageOpen = false;
    this.contactPageOpen = false;
    this.imprintOpen = false;
    this.helpOpen = false;
    this.termsOpen = false;
  }

  get selectedPageOpen(): boolean {
    return this._selectedPageOpen;
  }

  set selectedPageOpen(value: boolean) {
    this._selectedPageOpen = value;
  }

  get feedbackPageOpen(): boolean {
    return this._feedbackPageOpen;
  }

  set feedbackPageOpen(value: boolean) {
    this._feedbackPageOpen = value;
  }

  get contactPageOpen(): boolean {
    return this._contactPageOpen;
  }

  set contactPageOpen(value: boolean) {
    this._contactPageOpen = value;
  }

  get imprintOpen(): boolean {
    return this._imprintOpen;
  }

  set imprintOpen(value: boolean) {
    this._imprintOpen = value;
  }

  get helpOpen(): boolean {
    return this._helpOpen;
  }

  set helpOpen(value: boolean) {
    this._helpOpen = value;
  }

  get termsOpen(): boolean {
    return this._termsOpen;
  }

  set termsOpen(value: boolean) {
    this._termsOpen = value;
  }
}
