import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  GoogleAnalyticsAction,
  GoogleAnalyticsCategory,
} from '../../../../shared/services/google-analytics/google-analytics.model';
import { GoogleAnalyticsService } from '../../../../shared/services/google-analytics/google-analytics.service';
import { OpenPageModel } from '../../model/open-page.model';
import { OpenPageService } from '../../services/open-page.service';

@Component({
  selector: 'app-menu-controls',
  templateUrl: './menu-controls.component.html',
  styleUrls: ['./menu-controls.component.css'],
})
export class MenuControlsComponent implements OnInit {
  openPage: OpenPageModel;

  constructor(
    public translate: TranslateService,
    public openPageService: OpenPageService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    this.openPageService.currentOpenPage.subscribe(openPage => {
      this.openPage = openPage;
    });
  }

  openFeedBackView(): void {
    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.MENU,
      GoogleAnalyticsAction.OPENED_PAGE,
      'FEEDBACK'
    );
    this.openPage.feedbackPageOpen = true;
    this.openSelectedPage();
  }

  openContactView(): void {
    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.MENU,
      GoogleAnalyticsAction.OPENED_PAGE,
      'CONTACT'
    );
    this.openPage.contactPageOpen = true;
    this.openSelectedPage();
  }

  openImprintView(): void {
    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.MENU,
      GoogleAnalyticsAction.OPENED_PAGE,
      'IMPRINT'
    );
    this.openPage.imprintOpen = true;
    this.openSelectedPage();
  }

  openPrivacyView(): void {
    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.MENU,
      GoogleAnalyticsAction.OPENED_PAGE,
      'PRIVACY'
    );
    switch (this.translate.currentLang) {
      case 'de':
        window.open("https://www.compleo-charging.com/datenschutz", "_blank");
        break;
      default:
        window.open("https://www.compleo-charging.com/en/data-protection", "_blank");
    }
  }

  openTermsView(): void {
    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.MENU,
      GoogleAnalyticsAction.OPENED_PAGE,
      'TERMS'
    );
    this.openPage.termsOpen = true;
    this.openSelectedPage();
  }

  openHelpView(): void {
    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.MENU,
      GoogleAnalyticsAction.OPENED_PAGE,
      'HELP'
    );
    this.openPage.helpOpen = true;
    this.openSelectedPage();
  }

  private openSelectedPage(): void {
    this.openPage.selectedPageOpen = true;
    this.openPageService.changeOpenPage(this.openPage);
  }
}
