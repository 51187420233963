import { Injectable } from '@angular/core';
import {
  PriceComponent,
  Tariff,
  TARIFF_TYPES,
} from 'src/app/shared/models/bff/station-data.interface';
import * as isNil from 'lodash.isnil';

@Injectable({
  providedIn: 'root',
})
export class TariffService {
  private readonly DEFAULT_MIN_NUMBER_OF_DECIMALS = 2;

  public hasFreeParkingCostMinutes(tariff: Tariff): boolean {
    return !isNil(
      tariff?.priceComponents.find(
        (priceComponent: PriceComponent) =>
          priceComponent.price > 0 && priceComponent.freeParkingCostMinutes > 0
      )
    );
  }

  public getMinNumberOfDecimals(priceComponents: PriceComponent[]) {
    const calculateMinNumberOfDecimals = (
      currentMinNumberOfDecimals: number,
      priceComponent: PriceComponent
    ) => {
      const decimals = priceComponent?.price?.toString().split('.')[1]?.length || 0;
      return Math.max(decimals, currentMinNumberOfDecimals);
    };
    if (!priceComponents || priceComponents?.length == 0) {
      return this.DEFAULT_MIN_NUMBER_OF_DECIMALS;
    }
    return priceComponents?.reduce(
      (currentMinNumberOfDecimals, priceComponent) =>
        calculateMinNumberOfDecimals(currentMinNumberOfDecimals, priceComponent),
      this.DEFAULT_MIN_NUMBER_OF_DECIMALS
    );
  }

  public getTimeBasedPriceComponent(tariff: Tariff): PriceComponent {
    return tariff?.priceComponents.find(
      (priceComponent: PriceComponent) =>
        priceComponent.price > 0 && priceComponent.priceUnit === TARIFF_TYPES.CHARGING_TIME
    );
  }
}
