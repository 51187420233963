import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CanActivatePreparePaymentGuard } from '../shared/guards/can-activate-prepare-payment.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./start-page/start-page.module').then(m => m.StartPageModule),
  },
  {
    path: 'pay/',
    pathMatch: 'full',
    loadChildren: () => import('./start-page/start-page.module').then(m => m.StartPageModule),
  },
  {
    path: 'pay',
    pathMatch: 'full',
    loadChildren: () => import('./start-page/start-page.module').then(m => m.StartPageModule),
  },
  {
    path: 'payTest/',
    pathMatch: 'full',
    loadChildren: () => import('./start-page/start-page.module').then(m => m.StartPageModule),
  },
  {
    path: 'payTest',
    pathMatch: 'full',
    loadChildren: () => import('./start-page/start-page.module').then(m => m.StartPageModule),
  },
  {
    path: 'chargePort/:id',
    pathMatch: 'full',
    loadChildren: () => import('./start-page/start-page.module').then(m => m.StartPageModule),
  },
  {
    path: 'prepare-payment/:id',
    pathMatch: 'full',
    loadChildren: () =>
      import('./prepare-payment/prepare-payment.module').then(m => m.PreparePaymentModule),
    canActivate: [CanActivatePreparePaymentGuard],
  },
  {
    path: 'cookiesAndPixel',
    pathMatch: 'full',
    loadChildren: () =>
      import('./cookies-and-pixel/cookies-and-pixel.module').then(m => m.CookiesAndPixelModule),
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ComponentsRoutingModule {}
