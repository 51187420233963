export interface ChargingSession {
  receiptNumber: string;
  consumption: number;
  date: string;
  terminated: boolean;
  status: SESSION_STATUS;
  detailedStatus: SESSION_DETAILED_STATUS;
  duration: string;
  savingsCO2: number; // Not Used
  vatId: string;
  bdrId: string;
}

export enum SESSION_STATUS {
  'ERROR' = 'ERROR',
  'AUTHORIZATION_PENDING' = 'AUTHORIZATION_PENDING',
  'START_AUTHORIZED' = 'START_AUTHORIZED',
  'START_REQUESTED' = 'START_REQUESTED',
  'STARTED' = 'STARTED',
  'CHARGING' = 'CHARGING',
  'STOP_AUTHORIZED' = 'STOP_AUTHORIZED',
  'STOP_REQUESTED' = 'STOP_REQUESTED',
  'STOPPED' = 'STOPPED',
  'ENDED' = 'ENDED',
  'CLOSED' = 'CLOSED',
}

export enum SESSION_DETAILED_STATUS {
  'AUTHORIZATION_FAILED' = 'AUTHORIZATION_FAILED',
  'START_REQUEST_FAILED' = 'START_REQUEST_FAILED',
  'STOP_REQUEST_FAILED' = 'STOP_REQUEST_FAILED',
  'RESERVE_REQUEST_FAILED' = 'RESERVE_REQUEST_FAILED',
  'START_ACTIVATION_FAILED' = 'START_ACTIVATION_FAILED',
  'STOP_ACTIVATION_FAILED' = 'STOP_ACTIVATION_FAILED',
  'RESERVE_ACTIVATION_FAILED' = 'RESERVE_ACTIVATION_FAILED',
  'CHARGING_FAILED' = 'CHARGING_FAILED',
  'SEND_RDR_FAILED' = 'SEND_RDR_FAILED',
  'UNKNOWN_ERROR' = 'UNKNOWN_ERROR',
  'CONNECTION_FAILED' = 'CONNECTION_FAILED',
  'ABORTED' = 'ABORTED',
  'SDR_INVALID' = 'SDR_INVALID',
  'PERISHED' = 'PERISHED',
  'SUSPENDED' = 'SUSPENDED',
  'ACTIVE' = 'ACTIVE',
  'SUCCEEDED' = 'SUCCEEDED',
}
