import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChargeSessionStatusSlideDurationComponent } from './slide-duration/charge-session-status-slide-duration.component';
import { ChargeSessionStatusSlideLocationComponent } from './slide-location/charge-session-status-slide-location.component';
import { ChargeSessionStatusSlidePaymentComponent } from './slide-payment/charge-session-status-slide-payment.component';
import { ChargeSessionStatusSlidePowerComponent } from './slide-power/charge-session-status-slide-power.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [
    ChargeSessionStatusSlideDurationComponent,
    ChargeSessionStatusSlideLocationComponent,
    ChargeSessionStatusSlidePaymentComponent,
    ChargeSessionStatusSlidePowerComponent,
  ],
  exports: [
    ChargeSessionStatusSlideDurationComponent,
    ChargeSessionStatusSlideLocationComponent,
    ChargeSessionStatusSlidePaymentComponent,
    ChargeSessionStatusSlidePowerComponent,
  ],
})
export class CarouselModule {}
