import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import jsPDF from 'jspdf';
import * as moment from 'moment';
import { CustomCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';

import { ChargingSession } from '../../../../shared/models/bff/charging-session.interface';
import {
  ChargePoint,
  PriceComponent,
  StationDataInterface,
  Tariff,
  TARIFF_TYPES,
} from '../../../../shared/models/bff/station-data.interface';
import {
  GoogleAnalyticsAction,
  GoogleAnalyticsCategory,
} from '../../../../shared/services/google-analytics/google-analytics.model';
import { GoogleAnalyticsService } from '../../../../shared/services/google-analytics/google-analytics.service';
import { TariffService } from '../../../../shared/services/tariff.service';

@Component({
  selector: 'app-pdf-download',
  templateUrl: './pdf-download.component.html',
  styleUrls: ['./pdf-download.component.css'],
})
export class PdfDownloadComponent implements OnChanges {
  @Input()
  session: ChargingSession;

  @Input()
  station: StationDataInterface;

  @Input()
  chargePoint: ChargePoint;

  @Input()
  tariff: Tariff;

  @Input()
  disabled: boolean;

  @Input()
  grossPrice: string;

  @Input()
  netPrice: string;

  @Input()
  vatPrice: string;
  priceComponents: PriceComponent[] = [];
  public priceFormat: string;
  doc;

  constructor(
    private translate: TranslateService,
    private currencyPipe: CustomCurrencyPipe,
    private googleAnalyticsService: GoogleAnalyticsService,
    private tariffService: TariffService
  ) {}

  createPDF() {
    let textHeight = 10;
    const pageWidth = 210;
    const pageHeight = 297;
    const center = pageWidth / 2;
    const paddingLeft = pageWidth * 0.1;
    const paddingRight = pageWidth - paddingLeft;

    // @ts-ignore
    jsPDF.API.field = (left: string, right: string) => {
      this.doc.text(left, paddingLeft, textHeight);
      this.doc.text(right, paddingRight, textHeight, {
        align: 'right',
      });
      this.doc.setDrawColor('#D9D7D7');
      textHeight = textHeight + pageHeight * 0.018 * right.split('\n').length;
      this.doc.line(paddingLeft, textHeight, paddingRight, textHeight);
      textHeight += pageHeight * 0.023;
    };

    // @ts-ignore
    this.doc = new jsPDF({
      format: 'a4',
    });

    this.doc.setTextColor('#1A1718');
    this.doc.setFontSize(16);

    // Headline
    this.doc.text(this.translate.instant('summary.receipt'), center, textHeight, {
      align: 'center',
    });

    this.doc.setTextColor('#B4B1B2');
    this.doc.setFontSize(14);

    this.doc.text(this.chargePoint.name, center, (textHeight += 6), {
      align: 'center',
    });

    this.doc.setDrawColor(26, 23, 24, 0.6);
    this.doc.line(0, pageHeight * 0.1, 210, pageHeight * 0.1); // horizontal line

    this.doc.setTextColor('#1A1718');
    textHeight = pageHeight * 0.1413;

    // RECEIPT NUMBER
    this.doc.field(
      this.translate.instant('summary.receiptNumber'),
      (this.station && this.station.provider ? this.station.provider.id + ' / ' : '') +
        (this.session.receiptNumber || '')
    );

    // BDR ID
    this.doc.field(
      this.translate.instant('summary.bdrId'),
      this.session.bdrId ? this.session.bdrId : this.translate.instant('summary.pending')
    );

    // DATE
    this.doc.field(
      this.translate.instant('summary.date'),
      moment(this.session.date).format('lll') || ''
    );

    // TARIFF
    let tariffString = '';
    this.priceComponents =
      this.tariff &&
      Object.keys(TARIFF_TYPES)
        .map(key => this.tariff.priceComponents.find(component => component.priceUnit === key))
        .filter(component => component && component.price > 0);
    const minNumberOfDecimals = this.tariffService.getMinNumberOfDecimals(this.priceComponents);
    this.priceFormat = `1.${minNumberOfDecimals}-${minNumberOfDecimals}`;

    this.priceComponents?.forEach((component, index, array) => {
      if (index !== 0) {
        tariffString += '+ ';
      }

      tariffString += `${this.currencyPipe.transform(
        component.price,
        this.tariff.currency,
        this.priceFormat
      )}/${this.translate.instant(component.priceUnit)}`;

      if (component.freeParkingCostMinutes > 0) {
        tariffString += this.translate.instant('startPage.chargePortDetails.blocking-fee-suffix', {
          minutes: component.freeParkingCostMinutes,
        });
      }

      if (index !== array.length - 1) {
        tariffString += `\n`;
      }
    });
    this.doc.field(this.translate.instant('summary.tariff'), tariffString);

    // PROVIDER WITH ADDRESS
    this.doc.field(
      this.translate.instant('summary.provider'),
      this.station.provider.name +
        '\n' +
        this.station.provider.street +
        '\n' +
        this.station.provider.zipCode +
        ' ' +
        this.station.provider.city +
        '\n' +
        this.translate.instant('summary.vatId') +
        ' ' +
        (this.session.vatId ? this.session.vatId : '')
    );

    // STATION LOCATION
    this.doc.field(
      this.translate.instant('summary.location'),
      this.station.address.street +
        '\n' +
        this.station.address.zipCode +
        ' ' +
        this.station.address.city || ''
    );

    // PRICE
    this.doc.field(this.translate.instant('summary.netPrice'), this.netPrice);
    this.doc.field(
      this.translate.instant('summary.vat') + ' (' + this.station.vat + '%)',
      this.vatPrice
    );
    this.doc.field(this.translate.instant('summary.total'), this.grossPrice);

    // DISCLAIMER
    this.doc.text(this.translate.instant('summary.disclaimer'), paddingLeft, 200, {
      maxWidth: pageWidth - 2 * paddingLeft,
    });
  }

  savePDF() {
    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.SUMMARY,
      GoogleAnalyticsAction.DOWNLOAD,
      'User downloaded receipt'
    );
    const pdfName = this.chargePoint.name + '_' + this.session.date + '.pdf';
    this.doc.save(pdfName);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.station &&
      this.chargePoint &&
      this.session &&
      this.tariff &&
      this.grossPrice &&
      this.netPrice &&
      this.vatPrice
    ) {
      this.createPDF();
    }
  }
}
