import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariffComponent } from './tariff.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [TariffComponent],
  imports: [CommonModule, PipesModule, TranslateModule],
  exports: [TariffComponent],
})
export class TariffModule {}
