import { NgModule } from '@angular/core';
import { PriceComponentPipe } from 'src/app/shared/pipes/price-component.pipe';
import { CustomCurrencyPipe } from './currency.pipe';

@NgModule({
  declarations: [CustomCurrencyPipe, PriceComponentPipe],
  imports: [],
  exports: [CustomCurrencyPipe, PriceComponentPipe],
  providers: [CustomCurrencyPipe, PriceComponentPipe],
})
export class PipesModule {}
