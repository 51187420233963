import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialDesignModule } from '../../shared/modules/material-design/material-design.module';
import { LoadingOverlayComponent } from './loading-overlay.component';

@NgModule({
  imports: [CommonModule, TranslateModule, MaterialDesignModule],
  declarations: [LoadingOverlayComponent],
  exports: [LoadingOverlayComponent],
})
export class LoadingOverlayModule {}
