<div class="tariff-container" [ngClass]="{ centered }" *ngIf="tariff && priceComponents.length > 0">
  <div>
    <div
      class="page-contents--pricing-line1"
      *ngFor="let tariffComponent of priceComponents; first as isFirst"
    >
      {{ isFirst ? '' : '+ '
      }}{{ tariffComponent.price | customCurrency: tariff.currency : priceFormat }}/{{
        tariffComponent.priceUnit | translate
      }}
      <span *ngIf="tariffComponent.freeParkingCostMinutes > 0">
        {{
          'startPage.chargePortDetails.blocking-fee-suffix'
            | translate: { minutes: tariffComponent.freeParkingCostMinutes }
        }}
        <span *ngIf="hintIndicator">*</span>
      </span>
    </div>

    <div *ngIf="vat" class="page-contents--pricing-vat">
      {{ 'startPage.chargePortInfo.vat.prefix' | translate }} {{ vat }}
      {{ 'startPage.chargePortInfo.vat.suffix' | translate }}
    </div>
  </div>
</div>
