import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swiper from 'swiper';

import { DialogComponent } from '../../shared/components/dialog/dialog.component';
import { ChargingSession } from '../../shared/models/bff/charging-session.interface';
import { ChargePoint, StationDataInterface, Tariff } from '../../shared/models/bff/station-data.interface';
import { DynamicContentsService } from '../../shared/services/dynamic-contents.service';
import {
  GoogleAnalyticsAction,
  GoogleAnalyticsCategory,
} from '../../shared/services/google-analytics/google-analytics.model';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics/google-analytics.service';
import { LoadingIndicatorService } from '../../shared/services/loading-indicator.service';
import { SessionService } from '../../shared/services/session.service';
import { StationDataService } from '../../shared/services/station-data.service';

@Component({
  selector: 'app-charging-overlay',
  templateUrl: './charging-overlay.component.html',
  styleUrls: ['./charging-overlay.component.scss'],
})
export class ChargingOverlayComponent implements OnInit {
  activeBullet: HTMLElement;
  expanded = true;
  index = 0;

  // BFF
  chargingSession: ChargingSession;
  stationData: StationDataInterface;
  chargePoint: ChargePoint;
  tariff: Tariff;
  sessionId: boolean;
  carouselInitialized = false;
  showConsumptionSlide = false;

  private mySwiper;

  constructor(
    private sessionService: SessionService,
    private dynamicContentsService: DynamicContentsService,
    private stationDataService: StationDataService,
    private loadingIndicatorService: LoadingIndicatorService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private googleAnalyticsService: GoogleAnalyticsService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.sessionId = this.sessionService.getSessionExistence();

    this.sessionService.getSessionExistenceSubscription().subscribe(exists => {
      this.sessionId = exists;
    });

    this.sessionService.getSessionSubscription().subscribe(session => {
      if (!session) {
        this.chargingSession = null;
        this.sessionId = null;
        this.carouselInitialized = false;
      } else if (!this.chargingSession || this.chargingSession.date !== session.date) {
        this.stationData = JSON.parse(localStorage.getItem('station-info')).chargingStation;
        this.chargePoint = JSON.parse(localStorage.getItem('station-info')).selectedChargePoint;
        this.tariff = JSON.parse(localStorage.getItem('tariff'));
      }
      this.chargingSession = session;
      if (this.expanded && this.chargingSession && !this.carouselInitialized) {
        this.initCarousel();
        this.carouselInitialized = true;
      } else if (
        this.expanded &&
        this.chargingSession &&
        this.chargingSession.consumption === null &&
        this.showConsumptionSlide
      ) {
        this.initCarousel();
      } else if (
        this.expanded &&
        this.chargingSession &&
        this.chargingSession.consumption !== null &&
        !this.showConsumptionSlide
      ) {
        this.initCarousel();
      }
      this.cdr.detectChanges();
    });
  }

  toggleOverlay(expand) {
    if (this.chargingSession) {
      this.googleAnalyticsService.sendEvent(
        GoogleAnalyticsCategory.CHARGING_OVERLAY,
        GoogleAnalyticsAction.COLLAPSE,
        expand ? 'OPEN' : 'CLOSE'
      );
      this.expanded = expand;
      this.sessionService.isOverlayCollapsed(!this.expanded);
      this.cdr.detectChanges();
      if (expand) {
        this.initCarousel();
      }
    }
  }

  initCarousel() {
    if (this.chargingSession && !this.chargingSession.terminated) {
      this.showConsumptionSlide = this.chargingSession && this.chargingSession.consumption !== null;
      this.cdr.detectChanges();

      if (this.mySwiper) {
        this.mySwiper.destroy(true, true);
      }

      this.mySwiper = new Swiper('.swiper-container', {
        // Optional parameters
        direction: 'horizontal',
        loop: false,

        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
          bulletClass: 'icon-dot',
        },

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });

      const bullets = this.mySwiper.pagination.bullets;
      bullets[0].classList.add('charging-overlay-swiper-bullet');
      bullets[1].classList.add('charging-overlay-swiper-bullet');
      if (this.showConsumptionSlide) {
        bullets[2].classList.add('charging-overlay-swiper-bullet');
      }
      bullets[0].classList.add('icon-selected-dot');
      bullets[0].classList.remove('icon-dot');
      this.activeBullet = bullets[0];

      this.mySwiper.on('paginationUpdate', (swiper, el) => {
        this.activeBullet.classList.remove('icon-selected-dot');
        this.activeBullet.classList.add('icon-dot');

        if (bullets[0].classList.contains('swiper-pagination-bullet-active')) {
          bullets[0].classList.add('icon-selected-dot');
          bullets[0].classList.remove('icon-dot');
          this.activeBullet = bullets[0];
        } else if (bullets[1].classList.contains('swiper-pagination-bullet-active')) {
          bullets[1].classList.add('icon-selected-dot');
          bullets[1].classList.remove('icon-dot');
          this.activeBullet = bullets[1];
        } else if (
          this.showConsumptionSlide &&
          bullets[2].classList.contains('swiper-pagination-bullet-active')
        ) {
          bullets[2].classList.add('icon-selected-dot');
          bullets[2].classList.remove('icon-dot');
          this.activeBullet = bullets[2];
        }
      });
    }
  }

  stopCharging() {
    this.ngZone.run(() => {
      const DialogRef = this.dialog.open(DialogComponent, {
        data: {
          headline: 'session.dialog.headline',
          button1: 'session.dialog.yes',
          button2: 'session.dialog.no',
        },
      });

      DialogRef.afterClosed().subscribe(result => {
        if (result === 'yes') {
          this.googleAnalyticsService.sendEvent(
            GoogleAnalyticsCategory.CHARGING_OVERLAY,
            GoogleAnalyticsAction.STOP_CHARGING,
            'Stop button clicked'
          );
          this.loadingIndicatorService.setIndicator(true);
          this.sessionService.stopSession();
        }
      });
    });
  }

  clearSession() {
    this.sessionService.clearSession();
    this.expanded = true;
    this.index = 0;
    this.chargingSession = null;
    this.sessionId = null;
    this.stationData = null;
    this.chargePoint = null;
    this.tariff = null;
  }
}
