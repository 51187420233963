import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicContentsService } from '../../shared/services/dynamic-contents.service';
import { OpenPageService } from './services/open-page.service';
import { OpenPageModel } from './model/open-page.model';

@Component({
  selector: 'app-menu-pages',
  templateUrl: './menu-pages.component.html',
  styleUrls: ['./menu-pages.component.css'],
})
export class MenuPagesComponent implements OnInit {
  public empSpecificContents: any;

  public openPage: OpenPageModel;

  constructor(
    public dynamicContentsService: DynamicContentsService,
    private translate: TranslateService,
    private openPageService: OpenPageService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getEmpSpecificContents();
  }

  public getEmpSpecificContents() {
    this.dynamicContentsService.getDynamicContents().subscribe(dynamicContents => {
      this.empSpecificContents = dynamicContents;
      this.openPageService.currentOpenPage.subscribe(openPage => {
        this.openPage = openPage;
      });
    });
  }
}
