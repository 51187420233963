<div class="expansion-toggle expanded">
  <ng-container *ngIf="oneLineHeader">
    <h3>
      {{ 'summary.receipt' | translate }}
      <span class="chargepoint">{{ chargePoint?.name }} </span>
    </h3>

    <div class="icon-close close one-line" (click)="close()"></div>
  </ng-container>

  <ng-container *ngIf="!oneLineHeader">
    <h3>{{ 'summary.receipt' | translate }}</h3>

    <p class="chargepoint">{{ chargePoint?.name }}</p>

    <div class="icon-close close" (click)="close()"></div>
  </ng-container>
</div>

<div class="summary-container" [ngClass]="{ 'no-padding': noPadding }">
  <div class="section">
    <p class="title">{{ 'summary.receiptNumber' | translate }}</p>

    <p class="description">
      {{ station?.provider?.id ? station.provider.id + ' / ' : '' }}{{ session?.receiptNumber }}
    </p>

    <div style="clear: both"></div>
  </div>

  <div class="section">
    <p class="title">{{ 'summary.bdrId' | translate }}</p>

    <p class="description">
      {{ session?.bdrId ? session?.bdrId : ('summary.pending' | translate) }}
    </p>

    <div style="clear: both"></div>
  </div>

  <div class="section">
    <p class="title">{{ 'summary.date' | translate }}</p>

    <p class="description">{{ parseDateToLocale(session?.date || null) }}</p>

    <div style="clear: both"></div>
  </div>

  <div class="section">
    <p class="title">{{ 'summary.tariff' | translate }}</p>

    <p class="description">
      <app-tariff [tariff]="tariff" class="tariff"></app-tariff>
    </p>

    <div style="clear: both"></div>
  </div>

  <div class="section">
    <p class="title">{{ 'summary.provider' | translate }}</p>

    <p class="description">{{ station?.provider.name }}</p>

    <div style="clear: both"></div>

    <p class="description">{{ station?.provider.street }}</p>

    <div style="clear: both"></div>

    <p class="description">{{ station?.provider.zipCode }} {{ station?.provider.city }}</p>

    <div style="clear: both"></div>

    <p class="description">{{ 'summary.vatId' | translate }} {{ session?.vatId }}</p>

    <div style="clear: both"></div>
  </div>

  <div class="section">
    <p class="title">{{ 'summary.location' | translate }}</p>

    <p class="description">{{ station?.address.street }}</p>

    <div style="clear: both"></div>

    <p class="description">{{ station?.address.zipCode }} {{ station?.address.city }}</p>

    <div style="clear: both"></div>
  </div>

  <div class="section">
    <p class="title">{{ 'summary.netPrice' | translate }}</p>

    <p class="description">{{ getNetPrice() }}</p>

    <div style="clear: both"></div>
  </div>

  <div class="section">
    <p class="title">{{ ('summary.vat' | translate) + getVatPercentage() }}</p>

    <p class="description">{{ getVatAmount() }}</p>

    <div style="clear: both"></div>
  </div>

  <div class="section total">
    <p class="title">{{ 'summary.total' | translate }}</p>

    <p class="description">{{ getGrossPrice() }}</p>

    <div style="clear: both"></div>
  </div>

  <div class="information">
    <div class="disclaimer" [ngClass]="{ 'no-padding': noPadding }">
      {{ 'summary.disclaimer' | translate }}
    </div>

    <div class="pdf">
      <app-pdf-download
        [tariff]="tariff"
        [station]="station"
        [chargePoint]="chargePoint"
        [session]="session"
        [grossPrice]="getGrossPrice()"
        [netPrice]="getNetPrice()"
        [vatPrice]="getVatAmount()"
        [disabled]="!isValidReceipt()"
      ></app-pdf-download>
    </div>
  </div>
</div>
