import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IBrandingData, ICiData } from '../models/branding-data';

@Injectable({
  providedIn: 'root',
})
export class DynamicContentsService {
  private subject = new Subject<any>();

  constructor() {}

  public getDynamicContents(): Observable<IBrandingData> {
    return this.subject.asObservable();
  }

  public setDynamicContents(data: ICiData, lang: string) {
    let imprint: string;
    let contact: string;
    let instruction: string;
    let privacy: string;
    let terms: string;

    data.imprint[lang] && data.imprint[lang] !== ''
      ? (imprint = data.imprint[lang])
      : (imprint = data.imprint['en']);
    data.contact[lang] && data.contact[lang] !== ''
      ? (contact = data.contact[lang])
      : (contact = data.contact['en']);
    data.instruction[lang] && data.instruction[lang] !== ''
      ? (instruction = data.instruction[lang])
      : (instruction = data.instruction['en']);
    data.privacy[lang] && data.privacy[lang] !== ''
      ? (privacy = data.privacy[lang])
      : (privacy = data.privacy['en']);
    data.terms[lang] && data.terms[lang] !== ''
      ? (terms = data.terms[lang])
      : (terms = data.terms['en']);

    const ciData: IBrandingData = Object.assign(
      {},
      {
        // theme colors
        colors: {
          buttonBackgroundColor: data.buttonBackgroundColor,
          buttonTextColor: data.buttonTextColor,
          headerBackgroundColor: data.headerBackgroundColor,
          headerTextColor: data.headerTextColor,
          textColor: data.textColor,
        },

        // datetime for compatibility with old mdp
        datetime: new Date().toISOString(),

        // branded contents
        imprint,
        contact,
        instruction,
        privacy,
        terms,
      }
    );

    // save data
    localStorage.setItem('branding', JSON.stringify(ciData));
    this.subject.next(ciData);
  }
}
