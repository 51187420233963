<div class="charging-info-location">
  <h1 class="value">{{ chargePoint }}</h1>
  <div class="description">
    <p class="chargepoint-title">
      {{ 'session.status.slide-location.chargepoint-number' | translate }}
    </p>
    <p class="chargestation-name">{{ chargingStation }}</p>
    <p class="chargepoint-address">
      {{ address }}
    </p>
  </div>
</div>
