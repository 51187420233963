<div class="help-page__help-icon">
  <eop-icon identifier="speechbubble-questionmark" color="inherit"></eop-icon>
</div>

<div class="help-page__content">
  <h1 class="help-page__header">{{ 'global.help-footer.header' | translate }}</h1>

  <button branded (click)="openHelpView()" class="button-filled-primary mtop">
    {{ 'help-page.help' | translate }}
  </button>
  <button branded (click)="openContactView()" class="button-filled-primary">
    {{ 'help-page.contact-text' | translate }}
  </button>
  <div class="help-page__header help-page__header--sub-header">
    {{ 'help-page.subline-legal' | translate }}
  </div>
  <button branded (click)="openImprintView()" class="button-filled-primary mtop">
    {{ 'help-page.imprint' | translate }}
  </button>
  <button branded (click)="openPrivacyView()" class="button-filled-primary">
    {{ 'help-page.privacy' | translate }}
  </button>
  <button branded (click)="openTermsView()" class="button-filled-primary">
    {{ 'help-page.terms' | translate }}
  </button>
</div>

<div class="help-page__footer">
  <div class="copyright">&#169; 2024 vaylens GmbH</div>
</div>
