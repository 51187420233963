import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { EopIconModule } from '../../shared/components/eop-icon/eop-icon.module';
import { MaterialDesignModule } from '../../shared/modules/material-design/material-design.module';
import { ComponentsRoutingModule } from '../components-routing.module';
import { MenuPagesComponent } from './menu-pages.component';
import { OpenPageService } from './services/open-page.service';
import { ContactComponent } from './subcomponents/contact/contact.component';
import { FeedbackComponent } from './subcomponents/feedback/feedback.component';
import { HelpDetailsComponent } from './subcomponents/help-details/help-details.component';
import { ImprintComponent } from './subcomponents/imprint/imprint.component';
import { MenuControlsComponent } from './subcomponents/menu-controls/menu-controls.component';
import { TermsComponent } from './subcomponents/terms/terms.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsRoutingModule,
    MaterialDesignModule,
    EopIconModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    DirectivesModule,
  ],
  declarations: [
    MenuPagesComponent,
    ContactComponent,
    FeedbackComponent,
    ImprintComponent,
    HelpDetailsComponent,
    MenuControlsComponent,
    TermsComponent,
  ],
  exports: [MenuPagesComponent],
  providers: [OpenPageService],
})
export class MenuPagesModule {}
