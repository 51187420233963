import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OpenPageModel } from '../model/open-page.model';

@Injectable({
  providedIn: 'root',
})
export class OpenPageService {
  openPageSource = new BehaviorSubject(new OpenPageModel());
  currentOpenPage = this.openPageSource.asObservable();

  constructor() {}

  changeOpenPage(openPage: OpenPageModel): void {
    this.openPageSource.next(openPage);
  }

  closeOpenPage(): void {
    this.openPageSource.getValue().closeAllMenuPages();
  }
}
