<div class="charging-info-power">
  <div class="charging-info-icon">
    <svg
      version="1.1"
      id="innogy_picto_lightning.svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 85.04 85.04"
    >
      <title>innogy_picto_lightning</title>
      <path
        id="innogy_picto_lightning"
        fill="currentColor"
        shape-rendering="geometricPrecision"
        color-rendering="optimizeQuality"
        d="M47.63574,85.03906a2.05584,2.05584,0,0,1-.38379-0.03516,2.12539,2.12539,0,0,1-1.74316-2.09082V51.02344H32.25a4.19039,4.19039,0,0,1-4.06885-2.96094L15.42969,5.47412a4.37893,4.37893,0,0,1,.63818-3.85986A4.00338,4.00338,0,0,1,19.31445,0H53.29883a2.126,2.126,0,1,1,0,4.252H19.50244l0.00049,0.00244L32.23047,46.7627l13.93359,0.00879a3.664,3.664,0,0,1,3.59668,3.63672V71.291l15.71-41.50439,0.00879-.02295H48.34277a3.91262,3.91262,0,0,1-3.32129-1.40918c-1.04883-1.479-.4082-3.3042-0.10059-4.18164l2.80469-9.20264A2.12567,2.12567,0,1,1,51.792,16.21045L48.96,25.49658l-0.00488.01514H66.0459a3.64562,3.64562,0,0,1,3.06641,1.56543,4.56788,4.56788,0,0,1,.3291,4.23047L49.623,83.666A2.12609,2.12609,0,0,1,47.63574,85.03906Z"
      />
    </svg>
  </div>
  <h1 class="value">
    <small>{{ 'session.status.slide-power.circa' | translate }} </small
    >{{ powerValue | number: '1.2-2' }} <small>kWh</small>
  </h1>
  <div class="description">{{ 'session.status.slide-power.currently-loaded' | translate }}</div>
</div>
