import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { LoadingIndicatorService } from '../../shared/services/loading-indicator.service';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
})
export class LoadingOverlayComponent implements OnInit {
  public isLoading: boolean;
  constructor(
    private loadingIndicatorService: LoadingIndicatorService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isLoading = false;
    this.loadingIndicatorService.getIndicator().subscribe(isLoading => {
      this.isLoading = isLoading;
      this.cdr.detectChanges();
    });
  }
}
