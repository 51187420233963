const MAIN_URL = 'https://qa.epowerdirect.com/';
const BFF_DOMAIN = 'https://apiqa.services-emobility.com/pay';
const BFF_BASE_PATH = 'api';
const DEFAULT_PROVIDER_ID = 4168344;

export const environment = {
  production: false,
  bffDomain: BFF_DOMAIN,
  bffUrl: `${BFF_DOMAIN}/${BFF_BASE_PATH}`,
  mainUrl: MAIN_URL,
  defaultProviderId: DEFAULT_PROVIDER_ID,
  hotlineNumber: '08009944023',
  paymentMode: 'test',
  googleTagManager: 'false',
};
