import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
import {
  GoogleAnalyticsAction,
  GoogleAnalyticsCategory,
  GoogleAnalyticsEventWrapper,
  GoogleAnalyticsLabel,
} from './google-analytics.model';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService
  ) {}

  listenToRouteChanges() {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        this.sendEvent(
          GoogleAnalyticsCategory.PAGE_VISIT,
          GoogleAnalyticsAction.URL_CHANGED,
          item.url ? this.harmonizeUrl(item.url.split('?')[0]) : 'NO_URL'
        );
      }
    });
  }

  harmonizeUrl(url: string) {
    const chargePortIndex = url.indexOf('/chargePort/');
    const preparePaymentIndex = url.indexOf('/prepare-payment/');
    if (chargePortIndex !== -1) {
      return url.substring(0, chargePortIndex + 11);
    } else if (preparePaymentIndex !== -1) {
      return url.substring(0, preparePaymentIndex + 16);
    } else {
      return url;
    }
  }

  sendEvent(
    category: GoogleAnalyticsCategory,
    action: GoogleAnalyticsAction,
    label: GoogleAnalyticsLabel | string
  ) {
    const gtmTag: GoogleAnalyticsEventWrapper = {
      event: [
        {
          eventInfo: {
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
          },
        },
      ],
    };

    if (environment.production) {
      this.gtmService.pushTag(gtmTag);
    }
  }
}
