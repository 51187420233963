import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { IBrandingData } from '../models/branding-data';
import { DynamicContentsService } from '../services/dynamic-contents.service';

@Directive({
  selector: '[branded]',
})
export class BrandedDirective implements OnInit, OnDestroy {
  sub: Subscription;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private dynamicContentsService: DynamicContentsService
  ) {}
  private classObserver: MutationObserver;

  ngOnInit() {
    this.applyBrandingFromState();
    this.listenToBrandingChanges();
    this.listenToClassChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.classObserver.disconnect();
  }

  private listenToBrandingChanges() {
    this.sub = this.dynamicContentsService
      .getDynamicContents()
      .subscribe((ciData: IBrandingData) => {
        this.applyBrandingColors(ciData);
      });
  }

  private listenToClassChanges() {
    this.classObserver = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class') {
          this.applyBrandingFromState();
        }
      });
    });
    this.classObserver.observe(this.el.nativeElement, {
      attributes: true,
      attributeFilter: ['class'],
    });
  }

  private applyBrandingFromState() {
    const branding = localStorage.getItem('branding');
    if (branding) {
      this.applyBrandingColors(JSON.parse(branding));
    }
  }

  private applyBrandingColors(ciData: IBrandingData) {
    const element: HTMLElement = this.el.nativeElement;
    const buttonBgColor = ciData.colors.buttonBackgroundColor;
    const buttonTextColor = ciData.colors.buttonTextColor;

    if (element.classList.contains('button-filled-primary')) {
      this.setButtonColors(buttonBgColor, buttonTextColor);
    } else if (element.classList.contains('button-filled-secondary')) {
      this.setButtonColors(colorShade(buttonBgColor, 120), colorShade(buttonBgColor, -60));
    }
    if (element.classList.contains('disabled')) {
      this.renderer.setStyle(this.el.nativeElement, 'opacity', '0.3');
    } else {
      this.renderer.setStyle(this.el.nativeElement, 'opacity', '1');
    }
  }

  private setButtonColors(bg: string, text: string) {
    this.renderer.setStyle(this.el.nativeElement, 'background-color', bg);
    this.renderer.setStyle(this.el.nativeElement, 'color', text);
    const icon = this.el.nativeElement.querySelector('eop-icon');
    if (icon) {
      this.renderer.setStyle(icon, 'color', text);
    }
  }
}

const colorShade = (col, amt) => {
  col = col.replace(/^#/, '');
  if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

  let [r, g, b] = col.match(/.{2}/g);
  [r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt];

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? '0' : '') + r;
  const gg = (g.length < 2 ? '0' : '') + g;
  const bb = (b.length < 2 ? '0' : '') + b;

  return `#${rr}${gg}${bb}`;
};
