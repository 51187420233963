import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OpenPageService } from '../../services/open-page.service';
import { OpenPageModel } from '../../model/open-page.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  openPage: OpenPageModel;

  @Input()
  empSpecificContents: any;

  public lang: string;

  constructor(public translate: TranslateService, public openPageService: OpenPageService) {}

  ngOnInit() {
    this.openPageService.currentOpenPage.subscribe(openPage => (this.openPage = openPage));
  }

  closeContact(): void {
    this.openPage.contactPageOpen = false;
    this.openPage.selectedPageOpen = false;
    this.openPageService.changeOpenPage(this.openPage);
  }
}
