import { formatNumber, getCurrencySymbol } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale) {}

  transform(value: string | number, currencyCode: string, digitsInfo: string = '1.2-2'): string {
    const currencySymbol = getCurrencySymbol(currencyCode, 'narrow');
    const number = formatNumber(Number(value), this.locale, digitsInfo);
    return `${number} ${currencySymbol}`;
  }
}
