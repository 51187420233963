<div class="help-page__header help-page__header--sub-header">
  {{ 'help-page.help' | translate }}
</div>
<div (click)="closeHelp()" class="icon-close help-page__content--close-button"></div>

<div [hidden]="detailsShown">
  <!-- Slider main container -->
  <div class="swiper-container">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      <div class="swiper-slide">
        <h2>{{ 'help-page.step1' | translate }}</h2>
        <div class="slide-1 help-slides"></div>
      </div>

      <div class="swiper-slide">
        <h2>{{ 'help-page.step2' | translate }}</h2>
        <div class="slide-2 help-slides"></div>
      </div>

      <div class="swiper-slide">
        <h2>{{ 'help-page.step3' | translate }}</h2>
        <div class="slide-3 help-slides"></div>
      </div>

      <div class="swiper-slide">
        <h2>{{ 'help-page.step4' | translate }}</h2>
        <div class="slide-4 help-slides"></div>
      </div>
    </div>
    <!-- If we need pagination -->
    <div class="swiper-pagination"></div>
  </div>

  <button branded class="detailed-instructions button-filled-primary" (click)="detailsShown = true">
    {{ 'help-page.detailed' | translate }}
  </button>
</div>
<div
  [hidden]="!detailsShown"
  class="help-page__content"
  [innerHTML]="empSpecificContents.instruction"
></div>
