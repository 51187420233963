<mat-toolbar class="header">
  <div *ngIf="backButtonVisible()" class="icon back">
    <eop-icon
      class="header-arrow-left"
      identifier="header-arrow-left"
      [clickable]="true"
      (click)="opened ? closeMenuPages(sidenav) : navigateBack()"
      color="inherit"
    ></eop-icon>
  </div>
  <div class="header-logo">
    <eop-icon identifier="header" color="inherit"></eop-icon>
  </div>
  <div class="icon header-help">
    <eop-icon
      identifier="header-help"
      [clickable]="true"
      (click)="sidenav.toggle(); menuOpened(true)"
      color="inherit"
    ></eop-icon>
  </div>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav-content
    [ngClass]="{
      'background-map': showBackgroundMap,
      'charging-overlay-collapsed': isChargingOverlayCollapsed$ | async
    }"
  >
    <div class="main-content">
      <router-outlet (activate)="setActiveComponent($event)"></router-outlet>
    </div>
  </mat-sidenav-content>

  <mat-sidenav
    #sidenav
    mode="push"
    class="sidenav-drawer"
    [(opened)]="opened"
    position="end"
    (opened)="menuOpened()"
    (closed)="menuClosed()"
  >
    <div class="sidenav-content">
      <app-menu-pages></app-menu-pages>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
<!-- *ngIf="storage && storage.session" -->
<app-charging-overlay> </app-charging-overlay>

<!-- *ngIf="isLoading" -->
<app-loading-overlay> </app-loading-overlay>
