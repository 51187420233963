import { Routes } from '@angular/router';

import { CanActivatePreparePaymentGuard } from './shared/guards/can-activate-prepare-payment.guard';

export const ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule),
  },
  {
    path: 'chargePort/:id',
    loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule),
  },
  {
    path: 'cookiesAndPixel',
    loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule),
  },
  {
    path: 'prepare-payment/:id',
    pathMatch: 'full',
    loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule),
    canActivate: [CanActivatePreparePaymentGuard],
  },
  {
    path: '**',
    redirectTo: '/',
  },
];
