<div class="help-page">
  <app-menu-controls *ngIf="!openPage?.selectedPageOpen"> </app-menu-controls>

  <app-contact
    [empSpecificContents]="empSpecificContents"
    *ngIf="openPage?.selectedPageOpen && openPage?.contactPageOpen"
  >
  </app-contact>
  <app-imprint
    *ngIf="openPage?.selectedPageOpen && openPage?.imprintOpen"
    [empSpecificContents]="empSpecificContents"
  >
  </app-imprint>
  <app-terms
    *ngIf="openPage?.selectedPageOpen && openPage?.termsOpen"
    [empSpecificContents]="empSpecificContents"
  >
  </app-terms>
  <app-help-details
    *ngIf="openPage?.selectedPageOpen && openPage?.helpOpen"
    [empSpecificContents]="empSpecificContents"
  >
  </app-help-details>


</div>
