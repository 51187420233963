import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PriceComponent } from 'src/app/shared/models/bff/station-data.interface';
import { CustomCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';

@Pipe({
  name: 'priceComponent',
})
export class PriceComponentPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private customCurrencyPipe: CustomCurrencyPipe
  ) {}
  transform(priceComponent: PriceComponent, currencyCode: string, numberOfDecimals = 2): string {
    return `${this.customCurrencyPipe.transform(
      priceComponent?.price,
      currencyCode,
      `1.${numberOfDecimals}-${numberOfDecimals}`
    )}/${this.translate.instant(priceComponent?.priceUnit)}`;
  }
}
