<div>
  <h3 class="centered title" *ngIf="data.headline">{{ data.headline | translate }}</h3>
  <div class="text" *ngIf="data.text" [innerHTML]="data.text | translate"></div>
  <div class="centered">
    <button *ngIf="data.button1" [mat-dialog-close]="'yes'" class="button-filled-primary">
      {{ data.button1 | translate }}
    </button>
    <button *ngIf="data.button2" [mat-dialog-close]="'no'" class="button-filled-secondary">
      {{ data.button2 | translate }}
    </button>
  </div>
</div>
