import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorService {
  private subject = new Subject<boolean>();

  constructor() {}

  public setIndicator(isLoading) {
    localStorage.setItem('isLoading', JSON.stringify(isLoading));
    this.subject.next(isLoading);
  }

  public resetIndicator() {
    localStorage.setItem('isLoading', JSON.stringify(false));
    this.subject.next(false);
  }

  public getIndicator(): Observable<any> {
    return this.subject.asObservable();
  }
}
