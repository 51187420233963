import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StationDataService {
  private subject = new Subject<any>();

  public stationData: any;

  constructor() {}

  public getStationData(): Observable<any> {
    return of(JSON.parse(localStorage.getItem('station-info')));
  }

  public setStationData(data) {
    localStorage.setItem('station-info', JSON.stringify(data));
    this.subject.next(data);
  }

  public clearStationData() {
    localStorage.setItem('station-info', JSON.stringify({}));
    this.subject.next();
  }
}
