import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { LiveDataService } from './live-data.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MdpMissingTranslationHandler implements MissingTranslationHandler {
  public langFilePath = '../../../assets/i18n/en.json';
  public defaultLanguageJson: any;
  private destroy$ = new Subject<void>();

  constructor(public http: LiveDataService) {
    this.getDefaultLanguageKeys();
  }

  public getDefaultLanguageKeys() {
    this.getDefaultLanguageJSON()
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.defaultLanguageJson = response; // JSON.parse(response);
      });
  }

  public parseObject(defaultLanguageJson, key) {
    key = key.split('.');

    key.forEach(k => {
      defaultLanguageJson = Object.assign({}, defaultLanguageJson[k]);
    });
    return Object.values(defaultLanguageJson).join('');
  }

  public getDefaultLanguageJSON(): Observable<any> {
    return this.http.getStaticData(this.langFilePath);
  }

  handle(params: MissingTranslationHandlerParams) {
    if (this.defaultLanguageJson) {
      return this.parseObject(this.defaultLanguageJson, params.key) || params.key;
    } else {
      return params.key;
    }
  }
}
