import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OpenPageService } from '../../services/open-page.service';
import { OpenPageModel } from '../../model/open-page.model';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css'],
})
export class TermsComponent implements OnInit {
  openPage: OpenPageModel;

  lang: string;

  @Input()
  empSpecificContents: any;

  constructor(public translate: TranslateService, public openPageService: OpenPageService) {}

  ngOnInit() {
    this.openPageService.currentOpenPage.subscribe(openPage => (this.openPage = openPage));
  }

  closeTerms(): void {
    this.openPage.termsOpen = false;
    this.openPage.selectedPageOpen = false;
    this.openPageService.changeOpenPage(this.openPage);
  }
}
