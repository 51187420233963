import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as isNil from 'lodash.isnil';
import * as moment from 'moment';
import { concat, Subject, throwError, of } from 'rxjs';
import { delay, retryWhen, switchMap, take, takeUntil } from 'rxjs/operators';
import { SessionRating } from 'src/app/shared/models/bff/session-rating.interface';
import { CustomCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';

import { ChargingSession } from '../../../shared/models/bff/charging-session.interface';
import {
  ChargePoint,
  StationDataInterface,
  Tariff,
} from '../../../shared/models/bff/station-data.interface';
import { SessionService } from '../../../shared/services/session.service';

/**
 * Charging session summary screen, based on bill detail record from rating api.
 */
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnDestroy {
  @Input()
  session: ChargingSession;

  @Input()
  station: StationDataInterface;

  @Input()
  chargePoint: ChargePoint;

  @Input()
  tariff: Tariff;

  @Output()
  closed: EventEmitter<any> = new EventEmitter<any>();

  sessionRating: SessionRating;

  oneLineHeader = false;
  noPadding = false;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    public translate: TranslateService,
    private sessionService: SessionService,
    private customCurrencyPipe: CustomCurrencyPipe
  ) {}

  ngOnInit() {
    this.requestSessionRating();
    this.oneLineHeader = window.screen.height < 650;
    this.noPadding = window.screen.height < 600;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  requestSessionRating() {
    const storedSessionId = localStorage.getItem('sessionId');
    const RETRY_COUNT = 5;
    this.sessionService
      .isBdrAvailable(storedSessionId)
      .pipe(
        switchMap(isBdrAvailable =>
          isBdrAvailable
            ? this.fetchSessionRating(storedSessionId, RETRY_COUNT)
            : throwError('No bdr id is available')
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        sessionRating => (this.sessionRating = sessionRating),
        err => {
          console.debug('Catch error (setting values to 0): ', err);
          this.sessionRating = {
            vatCost: '0',
            grossCost: '0',
            netCost: '0',
            vatPercent: '0',
          };
        }
      );
  }

  private fetchSessionRating(storedSessionId: string, RETRY_COUNT: number) {
    return this.sessionService.fetchSessionRating(storedSessionId).pipe(
      switchMap(sessionRating =>
        sessionRating ? of(sessionRating) : throwError(new Error('No rating available, retry...'))
      ),
      retryWhen(error =>
        error.pipe(delay(1000), take(RETRY_COUNT), obs =>
          concat(obs, throwError(`Number of retries (${RETRY_COUNT}) exceeded, show zero values.`))
        )
      )
    );
  }

  getGrossPrice(): string {
    if (!isNil(this.sessionRating?.grossCost)) {
      return this.customCurrencyPipe.transform(this.sessionRating.grossCost, this.tariff.currency);
    } else {
      return this.translate.instant('summary.pending');
    }
  }

  getNetPrice(): string {
    if (!isNil(this.sessionRating?.netCost)) {
      return this.customCurrencyPipe.transform(this.sessionRating.netCost, this.tariff.currency);
    } else {
      return this.translate.instant('summary.pending');
    }
  }

  getVatPercentage(): string {
    if (!isNil(this.sessionRating?.vatPercent)) {
      return ` (${this.sessionRating?.vatPercent} %)`;
    } else {
      return '';
    }
  }

  getVatAmount(): string {
    if (!isNil(this.sessionRating?.vatCost)) {
      return this.customCurrencyPipe.transform(this.sessionRating.vatCost, this.tariff.currency);
    } else {
      return this.translate.instant('summary.pending');
    }
  }

  isValidReceipt(): boolean {
    return !isNil(this.sessionRating?.grossCost) &&
      !isNil(this.sessionRating?.netCost) &&
      !isNil(this.sessionRating?.vatCost);
  }

  close() {
    this.closed.emit(true);
  }

  parseDateToLocale(date): string {
    if (date) {
      return moment(date).format('lll');
    } else {
      return '';
    }
  }
}
