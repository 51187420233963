import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { StationDataService } from '../services/station-data.service';

@Injectable({
  providedIn: 'root',
})
export class CanActivatePreparePaymentGuard implements CanActivate {
  constructor(public stationDataService: StationDataService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (
      JSON.parse(localStorage.getItem('station-info')) &&
      JSON.parse(localStorage.getItem('station-info'))['chargingAllowed']
    ) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
