import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swiper from 'swiper';

import { OpenPageModel } from '../../model/open-page.model';
import { OpenPageService } from '../../services/open-page.service';

@Component({
  selector: 'app-help-details',
  templateUrl: './help-details.component.html',
  styleUrls: ['./help-details.component.scss'],
})
export class HelpDetailsComponent implements OnInit {
  openPage: OpenPageModel;
  detailsShown = false;
  activeBullet: HTMLElement;

  @Input()
  empSpecificContents: any;

  lang: string;

  constructor(public translate: TranslateService, public openPageService: OpenPageService) {}

  ngOnInit() {
    this.openPageService.currentOpenPage.subscribe(openPage => {
      this.openPage = openPage;
      this.initCarousel();
    });
  }

  initCarousel() {
    const mySwiper = new Swiper('.swiper-container', {
      // Optional parameters
      direction: 'horizontal',
      loop: true,

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        bulletClass: 'icon-dot',
      },
    });
    if (!mySwiper || !mySwiper.pagination || !mySwiper.pagination.bullets) {
      return;
    }
    const bullets = mySwiper.pagination.bullets;
    bullets[0].classList.add('icon-selected-dot');
    bullets[0].classList.remove('icon-dot');
    this.activeBullet = bullets[0];

    mySwiper.on('paginationUpdate', (swiper, el) => {
      this.activeBullet.classList.remove('icon-selected-dot');
      this.activeBullet.classList.add('icon-dot');

      if (bullets[0].classList.contains('swiper-pagination-bullet-active')) {
        bullets[0].classList.add('icon-selected-dot');
        bullets[0].classList.remove('icon-dot');
        this.activeBullet = bullets[0];
      } else if (bullets[1].classList.contains('swiper-pagination-bullet-active')) {
        bullets[1].classList.add('icon-selected-dot');
        bullets[1].classList.remove('icon-dot');
        this.activeBullet = bullets[1];
      } else if (bullets[2].classList.contains('swiper-pagination-bullet-active')) {
        bullets[2].classList.add('icon-selected-dot');
        bullets[2].classList.remove('icon-dot');
        this.activeBullet = bullets[2];
      } else if (bullets[3].classList.contains('swiper-pagination-bullet-active')) {
        bullets[3].classList.add('icon-selected-dot');
        bullets[3].classList.remove('icon-dot');
        this.activeBullet = bullets[3];
      }
    });
  }

  public closeHelp(): void {
    this.openPage.helpOpen = false;
    this.openPage.selectedPageOpen = false;
    this.openPageService.changeOpenPage(this.openPage);
  }
}
