import { Component, Input } from '@angular/core';

@Component({
  selector: 'ec-charge-session-status-slide-location',
  templateUrl: './charge-session-status-slide-location.component.html',
  styleUrls: ['./charge-session-status-slide-location.component.scss'],
})
export class ChargeSessionStatusSlideLocationComponent {
  @Input()
  chargePoint = '';

  @Input()
  chargingStation = '';

  @Input()
  address = '';

  constructor() {}
}
