import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LiveDataService {
  constructor(private httpClient: HttpClient) {}

  public delete<T>(url: string, options?): Observable<T> {
    return this.performRequest('DELETE', url, options);
  }

  public get<T>(url: string, options?): Observable<T> {
    return this.performRequest('GET', url, options);
  }

  public getStaticData<T>(url: string, options?): Observable<T> {
    return this.performStaticDataRequest('GET', url, options);
  }

  public post<T>(url: string, body: any | null = null, options?): Observable<T> {
    const postBody = { body };
    const optionsObject = Object.assign({}, options, postBody);
    return this.performRequest('POST', url, optionsObject);
  }

  public put<T>(url: string, body: any | null, options?): Observable<T> {
    return this.performRequest('PUT', url, { options, body });
  }

  public patch<T>(url: string, body: any | null, options?): Observable<T> {
    return this.performRequest('PATCH', url, { options, body });
  }

  private performRequest(method: string, url: string, options?): Observable<any> {
    url = environment.bffUrl + url;
    return this.httpClient.request(method, url, options);
  }

  private performStaticDataRequest(method: string, url: string, options?): Observable<any> {
    return this.httpClient.request(method, url, options);
  }
}
