<div
  *ngIf="(sessionId && !chargingSession) || (chargingSession && !chargingSession.terminated)"
  class="overlay"
  [ngClass]="{ expanded: expanded }"
>
  <div *ngIf="!expanded" class="expansion-toggle" (click)="toggleOverlay(true)">
    <p>
      {{
        (chargingSession?.status ? 'session.status.' + chargingSession?.status : null) ||
          'session.status.WAITING' | translate
      }}
      ({{ chargingSession?.duration ? chargingSession?.duration.substr(0, 4) : '00:00' }})
      <eop-icon [identifier]="'arrow-down'" class="toggle-overlay-icon"></eop-icon>
    </p>
  </div>

  <div *ngIf="expanded">
    <div class="expansion-toggle expanded" (click)="toggleOverlay(false)">
      <p>
        {{ 'global.hide' | translate }}
        <eop-icon [identifier]="'arrow-down'" class="toggle-overlay-icon"></eop-icon>
      </p>
    </div>
    <div class="header-text">
      <h1>
        {{
          (chargingSession?.status !== 'ERROR'
            ? chargingSession?.status
              ? 'session.status.' + chargingSession.status
              : null
            : 'session.status.' + chargingSession?.detailedStatus) || 'session.status.WAITING'
            | translate
        }}
      </h1>
    </div>

    <div class="battery-background">
      <div class="battery-image">
        <svg
          width="100px"
          height="240px"
          viewBox="0 0 100 240"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <!-- Generator: Sketch 48.2 (47327) - http://www.bohemiancoding.com/sketch -->
          <title>illu_battery_outline_white</title>
          <desc>Created with Sketch.</desc>
          <defs></defs>
          <g
            id="Artboard"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            transform="translate(-3363.000000, -995.000000)"
          >
            <g
              id="illu_battery_outline_white"
              transform="translate(3363.000000, 995.000000)"
              stroke="currentColor"
              stroke-width="3"
            >
              <path
                d="M65.6907854,1.5 L34.3078922,1.5 L34.3078922,14.8859781 L13.0297941,14.8859781 C6.68267167,14.8859781 1.5,20.3963716 1.5,27.240135 L1.5,226.147249 C1.5,232.988903 6.68333284,238.5 13.0297941,238.5 L86.9702059,238.5 C93.3166672,238.5 98.5,232.988903 98.5,226.147249 L98.5,27.240135 C98.5,20.3963716 93.3173283,14.8859781 86.9702059,14.8859781 L65.6907854,14.8859781 L65.6907854,1.5 Z"
                id="battery_top"
              ></path>
            </g>
          </g>
        </svg>
      </div>
    </div>

    <!-- Slider main container -->
    <div class="swiper-container">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div [ngClass]="{ 'swiper-slide': showConsumptionSlide }" *ngIf="showConsumptionSlide">
          <ec-charge-session-status-slide-power
            [powerValue]="chargingSession?.consumption || 0"
          ></ec-charge-session-status-slide-power>
        </div>
        <div class="swiper-slide">
          <ec-charge-session-status-slide-duration
            [duration]="chargingSession?.duration || '0:00:00'"
          >
          </ec-charge-session-status-slide-duration>
        </div>
        <div class="swiper-slide">
          <ec-charge-session-status-slide-location
            [chargePoint]="chargePoint?.name || ''"
            [chargingStation]="stationData?.name || ''"
            [address]="
              stationData
                ? stationData.address?.street +
                  ', ' +
                  stationData.address?.zipCode +
                  ' ' +
                  stationData.address?.city
                : ''
            "
          >
          </ec-charge-session-status-slide-location>
        </div>
      </div>
      <!-- If we need pagination -->
      <div class="swiper-pagination"></div>

      <!-- If we need navigation buttons -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>

    <button class="button-filled-primary charging-stop-button" (click)="stopCharging()">
      <eop-icon identifier="stop" class="stop"></eop-icon>
      {{ 'session.stop' | translate }}
    </button>
  </div>
</div>

<div *ngIf="chargingSession && chargingSession.terminated" class="overlay summary">
  <app-summary
    [session]="chargingSession"
    [chargePoint]="chargePoint"
    [station]="stationData"
    [tariff]="tariff"
    (closed)="clearSession()"
  ></app-summary>
</div>
