import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OpenPageService } from '../../services/open-page.service';
import { OpenPageModel } from '../../model/open-page.model';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.css'],
})
export class ImprintComponent implements OnInit {
  openPage: OpenPageModel;

  @Input()
  empSpecificContents: any;

  lang: string;

  constructor(public translate: TranslateService, public openPageService: OpenPageService) {}

  ngOnInit() {
    this.openPageService.currentOpenPage.subscribe(openPage => (this.openPage = openPage));
  }

  closeImprint(): void {
    this.openPage.imprintOpen = false;
    this.openPage.selectedPageOpen = false;
    this.openPageService.changeOpenPage(this.openPage);
  }
}
