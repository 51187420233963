import { Component, Input, OnInit } from '@angular/core';
import { TariffService } from 'src/app/shared/services/tariff.service';
import { PriceComponent, TARIFF_TYPES, Tariff } from '../../models/bff/station-data.interface';

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.scss'],
})
export class TariffComponent implements OnInit {
  @Input()
  tariff: Tariff;

  @Input()
  vat: number;

  @Input()
  hintIndicator: boolean;

  @Input()
  centered: boolean = false;

  priceComponents: PriceComponent[] = [];

  public priceFormat: string;

  private readonly priceTypeDisplayOrder = [
    TARIFF_TYPES.CHARGING_KWH,
    TARIFF_TYPES.CHARGING_TIME,
    TARIFF_TYPES.CHARGING_SESSION,
    TARIFF_TYPES.PARKING_TIME,
    TARIFF_TYPES.PARKING_SESSION,
  ];

  constructor(private tariffService: TariffService) {}

  ngOnInit(): void {
    this.priceComponents =
      this.tariff &&
      this.priceTypeDisplayOrder
        .map(key => this.tariff.priceComponents.find(component => component.priceUnit == key))
        .filter(component => component && component.price > 0);
    const minNumberOfDecimals = this.tariffService.getMinNumberOfDecimals(this.priceComponents);
    this.priceFormat = `1.${minNumberOfDecimals}-${minNumberOfDecimals}`;
  }
}
